<template>
    <draggable
        class="draggableContainer"
        v-model="draggableData"
        @end="listChangeHandle($event)" 
        :animation="80"
        draggable=".draggableItem"
    >
        <div v-for="(file,index) in draggableData" :key="index" class="draggableItem" :draggable="draggableData.length == 1 ? false : true">
            <single-video-uploader 
                :fileObject="file"
                :setFile="updateFile"
                :removeAsset="handleRemove"
                :removeSingleFile="singleFileRemoveHandle"
                @preview-event="handleImagePreview"
                :moduleType="moduleType"
            />
        </div>
        <div slot="footer" class="addMoreDraggableItem" draggable="false">
            <input 
                type="file" 
                name="file" 
                multiple="multiple" 
                class="uploadInput" 
                ref="fileUploader"
                @change="uploadHandle($event)"
                :disabled="isDisabled"
                :accept=" filesType == 'images' ? 'image/png,image/jpg,image/jpeg,image/webp' : filesType == 'videos' ? 'video/*' : '' "
            >
            <i class="el-icon-plus"></i>
        </div>

    </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import {mapGetters} from 'vuex';
import { MessageBox, Message, Loading } from "element-ui";
import singleVideoUploader from "./singleVideoUploader.vue";
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            url: process.env.VUE_APP_BASE_URL + '/product/video/add',
            draggableData:[],
            headers: {},
        }
    },
    props:{
        setFiles:{
            type: Function
        },
        draggableValues:{
            // type: Array,
            required: true,
            description: 'Files'
        },
        filesType:{
            type:String,
            required: true,
            description:'type of files'
        },
        isDisabled:{
            type:Boolean,
            default: false
        },
        moduleType:{
            type:String,
            required: true,
            description: 'module type',
            default: 'products'
        },
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
    },
    watch:{
        'draggableValues':{
            handler:function(value){
                this.draggableData = _.cloneDeep(value)
            },deep:true
        }
    },
    components:{
        draggable,
        singleVideoUploader,
    },
    methods:{
        listChangeHandle(e){

            if(JSON.stringify(this.draggableData) !== JSON.stringify(this.draggableValues)){

                this.assignOrder(true);

                this.setUpdatedData();

            }

        },
        async uploadHandle(e){

            if(e.target.files.length > 5){

                this.$notify({
                    type: "error",
                    title: "Alert",
                    message: "You are limited to uploading a maximum of 5 videos at a time.",
                }); 

                return

            }

            let validFiles = [];

            for(const file of e.target.files){
                
                if((this.filesType == 'videos') && file.type.includes("video/mp4")){

                    const video = document.createElement('video');
                    const objectURL = URL.createObjectURL(file);
                    
                    video.src = objectURL;

                    let duration = await this.loadVideoMetadata(video);

                    if(duration >= 60){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "The allowed video duration is limited to one minute (60 seconds).",
                        });

                    } else if(file.size > 52428800){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "The video size must not exceed 50MBs.",
                        });

                    }else{

                        validFiles.push(file);

                    }

                }else{
                    
                    if(file.type.includes("video/")){

                        if(!file.type.includes("video/mp4")){

                            this.$notify({
                                title: "Alert",
                                type: "error",
                                message: "The file format should be MP4.",
                            });

                        }

                    }else{

                        this.$notify({
                            title: "Alert",
                            type: "error",
                            message: "Uploaded file must be a video.",
                        });

                    }

                }

            }

            for(let i = 0;i < validFiles.length; i++){

                this.draggableData.push(validFiles[i]);

            }

            this.setUpdatedData();
            
        },
        updateFile(file){

            let uuid = uuidv4();

            let fileObj = {
                uid: uuid,
                name:file.name,
                status:'success',
                url:file.url,
                raw:{
                    originalFileName: file.orgName
                }
            }

            let index = this.draggableData.findIndex((dataItem) => dataItem instanceof File && dataItem.name == file.orgName);

            if(index != -1){

                this.draggableData.splice(index,0);

                this.draggableData[index] = fileObj;

                this.assignOrder(false);

                this.setUpdatedData();

            }
            
        },
        assignOrder(setFiles){

            for(let i = 0 ; i < this.draggableData.length ; i++){

                this.draggableData[i].current_index = i;

            }

            if(setFiles){

                this.setUpdatedData();

            }

        },
        setUpdatedData(){

            if(this.$refs.fileUploader){

                this.$refs.fileUploader.value = '';

            }

            this.setFiles(this.draggableData);

        },
        handleRemove(file){

            MessageBox.confirm(
                "Are you sure? video will be deleted permanently", 
                "Warning", 
                {
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                    type: "warning",
                }
            ).then(async () => {
                
                if(this.moduleType == 'products'){

                    let loader = Loading.service({
                        fullscreen: true,
                        text: 'The product video is being removed. Please Wait!',
                    });

                    let formData = new FormData();
                    formData.append('video',file.name);

                    try{
                        let res = await this.$axios.post('/product/video/remove/single',formData);
                        if(res.data.status_code == "5001"){

                            this.setRemoveData(file);

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "The video has been successfully removed.",
                            });

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });
                            
                        }

                    }finally{

                        loader.close();

                    }

                }else if(this.moduleType == 'product-bundles'){

                    let loader = Loading.service({
                        fullscreen: true,
                        text: 'The product bundle video is being removed. Please Wait!',
                    });

                    let formData = new FormData();
                    formData.append('video',file.name);

                    try{
                        let res = await this.$axios.post('product-bundle/video/remove/single',formData);
                        if(res.data.status_code == "5001"){

                            this.setRemoveData(file);

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "The video has been successfully removed.",
                            });

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });
                            
                        }

                    }finally{

                        loader.close();

                    }

                }

            }).catch(()=>{})

        },
        singleFileRemoveHandle(file){
            
            let index = this.draggableData.findIndex((dataItem)=> dataItem.name == file.name)

            if(index != -1){

                this.draggableData.splice(index,1);

                this.assignOrder(false);

                this.setUpdatedData();

            }else if(this.draggableData.some((file) =>  file instanceof File)){
                
                this.draggableData.pop();

                this.assignOrder(false);

                this.setUpdatedData();

            }

        },
        setRemoveData(file){

            let index = this.draggableData.findIndex((dataItem)=> dataItem.name == file.name);

            if(index != -1){

                this.draggableData.splice(index,1);

                this.assignOrder(false);

                this.setUpdatedData();

            }

        },
        handleImagePreview(file){

            this.$emit('preview-event',file);

        },
        async loadVideoMetadata(video){

            return new Promise((resolve, reject) => {

                video.addEventListener('loadedmetadata', () => {

                    resolve(video.duration);

                });

                video.addEventListener('error', (error) => {

                    reject(error);

                });

                video.load();

            });
            
        },
    },
    mounted(){

        this.draggableData = _.cloneDeep(this.draggableValues);
        
    }
}
</script>

<style>

</style>